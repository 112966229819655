import request from '@/utils/request'


// 查询船舶与用户绑定关系信息列表
export function listUserRelationship(query) {
  return request({
    url: '/ship/ship-user-relationship/list',
    method: 'get',
    params: query
  })
}

// 查询船舶与用户绑定关系信息分页
export function pageUserRelationship(query) {
  return request({
    url: '/ship/ship-user-relationship/page',
    method: 'get',
    params: query
  })
}

// 查询船舶与用户绑定关系信息详细
export function getUserRelationship(data) {
  return request({
    url: '/ship/ship-user-relationship/detail',
    method: 'get',
    params: data
  })
}

// 新增船舶与用户绑定关系信息
export function addUserRelationship(data) {
  return request({
    url: '/ship/ship-user-relationship/add',
    method: 'post',
    data: data
  })
}

// 修改船舶与用户绑定关系信息
export function updateUserRelationship(data) {
  return request({
    url: '/ship/ship-user-relationship/edit',
    method: 'post',
    data: data
  })
}

// 删除船舶与用户绑定关系信息
export function delUserRelationship(data) {
  return request({
    url: '/ship/ship-user-relationship/delete',
    method: 'post',
    data: data
  })
}
